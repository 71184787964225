import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { downloadBlobUrlFileToBrowser } from '@highlander/common/helpers/file.helpers';
import { getFilterFromLocalStorage } from '@highlander/services/helpers';
import { AssessmentListItem, AssessmentStatusFilter, AssessmentToggleOptions, IAssessment } from '@models/products-and-orders/assessments';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { catchError, EMPTY, pipe, switchMap, tap } from 'rxjs';
import { ASSESSMENTS_TABLE_ID } from 'src/app/app-pages/products-orders/assessments/assessments.component';
import { createCustomStateSetOnUrlHandler, createStateResetHandler, getAll, getOne, StateDictionary } from '../../helpers';
import { AssessmentsService } from './assessments.service';

type AssessmentsState = {
  assessments: AssessmentListItem[];
  isLoading: boolean;
  selectedAssessment: IAssessment | null;
  selectedAssessmentLoading: boolean;
  toggleOption: AssessmentToggleOptions;
  downloadLoading: boolean;
};
const initialState: AssessmentsState = {
  assessments: [],
  isLoading: false,
  selectedAssessment: null,
  selectedAssessmentLoading: false,
  toggleOption: AssessmentToggleOptions.Open,
  downloadLoading: false,
};

const assessmentsDictionary: StateDictionary<AssessmentListItem> = {
  entityList: 'assessments',
  listLoading: 'isLoading',
  selectedEntity: 'selectedAssessment',
  selectedEntityLoading: 'selectedAssessmentLoading',
  entityId: 'id',
  // unused
  createLoading: '',
};
export const AssessmentsStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withDevtools('assessments-store'),

  withComputed(state => ({
    selectedAssessmentId: computed(() => state.selectedAssessment()?.id || null),
    activeStatusFilters: computed(() =>
      state.toggleOption() === AssessmentToggleOptions.Open
        ? (['Saved', 'Submitted', 'Analyzing', 'OnHold', 'Completed'] as AssessmentStatusFilter)
        : [],
    ),
  })),

  withMethods((store, assessmentsService = inject(AssessmentsService), router = inject(Router)) => {
    createStateResetHandler<AssessmentsState>('/assessments')(store, initialState);
    createCustomStateSetOnUrlHandler<AssessmentsState>('/assessments', { selectedAssessment: null })(store);

    const savedFilter = getFilterFromLocalStorage(ASSESSMENTS_TABLE_ID);
    if (savedFilter?.toggleSearch) {
      patchState(store, {
        toggleOption: savedFilter.toggleSearch === 'All' ? AssessmentToggleOptions.All : AssessmentToggleOptions.Open,
      });
    }

    const repositoryMethods = {
      getAll: getAll<AssessmentListItem, AssessmentStatusFilter>({
        store,
        service: assessmentsService,
        dictionary: assessmentsDictionary,
      }),

      getOne: getOne<IAssessment>({
        store,
        service: assessmentsService,
        dictionary: assessmentsDictionary,
      }),

      downloadFile: rxMethod<{ id: string; fileId: string }>(
        pipe(
          tap(() => patchState(store, { downloadLoading: true })),
          switchMap(({ id, fileId }) =>
            assessmentsService.getDownloadUrl(id, fileId).pipe(
              tap(url => {
                downloadBlobUrlFileToBrowser(url.URL);
                patchState(store, {
                  downloadLoading: false,
                });
              }),
              catchError(error => {
                patchState(store, { downloadLoading: false });
                return EMPTY;
              }),
            ),
          ),
        ),
      ),

      downloadFileBulk: rxMethod<{ id: string; fileList: string[] }>(
        pipe(
          tap(() => patchState(store, { downloadLoading: true })),
          switchMap(({ id, fileList }) =>
            assessmentsService.getDownloadUrlBulk(id, fileList).pipe(
              tap(url => {
                downloadBlobUrlFileToBrowser(url.URL);
                patchState(store, {
                  downloadLoading: false,
                });
              }),
              catchError(error => {
                patchState(store, { downloadLoading: false });
                return EMPTY;
              }),
            ),
          ),
        ),
      ),

      resetState: (state: Partial<AssessmentsState>) => patchState(store, state),
    };

    const navigationActions = {
      goToAssessmentActions: (id: string): void => {
        repositoryMethods.getOne(id);
        router.navigate(['assessments', id]);
      },

      goToListPage: (): void => {
        patchState(store, {
          selectedAssessment: null,
        });
        router.navigate(['assessments']);
      },
    };
    return {
      ...repositoryMethods,
      ...navigationActions,

      loadAssessments: (toggle?: AssessmentToggleOptions) => {
        if (toggle !== undefined) {
          patchState(store, { toggleOption: toggle });
        }
        const filters = store.activeStatusFilters();
        repositoryMethods.getAll(filters);
      },
    };
  }),
);

export type AssessmentsStore = typeof AssessmentsStore extends infer T ? (T extends new (...args: any[]) => infer R ? R : T) : never;
