import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { commonDataSelectors } from '@appState';
import { CommonData } from '@models/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../app.store';
import * as fromActions from './common-data.actions';
import { CommonDataObj, CommonDataService } from './common-data.service';
@Injectable()
export class CommonDataEffects {
  onGetLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getLanguages),
      switchMap(() =>
        this.commonDataService.getSpecific<'language'>('language').pipe(
          map(languages => fromActions.getLanguagesComplete({ languages })),
          catchError(err => of(fromActions.getLanguagesError({ err }))),
        ),
      ),
    ),
  );

  onGetMembershipLevels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getMembershipLevels),
      switchMap(() =>
        this.commonDataService.getSpecific<'membershipLevel'>('membershipLevel').pipe(
          map(membershipLevels => fromActions.getMembershipLevelsComplete({ membershipLevels })),
          catchError(err => of(fromActions.getMembershipLevelsError({ err }))),
        ),
      ),
    ),
  );

  onGetRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getRegions),
      switchMap(() =>
        this.commonDataService.getSpecific<'region'>('region').pipe(
          map(regions => fromActions.getRegionsComplete({ regions })),
          catchError(err => of(fromActions.getRegionsError({ err }))),
        ),
      ),
    ),
  );

  onGetAllCommonData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllCommonData),
      switchMap(() =>
        this.commonDataService.getAll().pipe(
          map(commonData => fromActions.getAllCommonDataComplete({ commonData })),
          catchError(err => of(fromActions.getAllCommonDataError({ err }))),
        ),
      ),
    ),
  );

  onGoToCommonDataActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToCommonDataActions),
        tap(({ id }) => this.router.navigate(['common-data', id])),
      ),
    { dispatch: false },
  );

  onSelectCommonData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectCommonData),
      switchMap(({ id }) =>
        this.store.pipe(
          select(commonDataSelectors.selectCommonData),
          take(1),
          switchMap(commonData => {
            const commonDataItem = findCommonDataInRecords(id, commonData);
            if (commonDataItem) {
              return of(fromActions.selectCommonDataComplete({ commonData: commonDataItem }));
            } else {
              return this.commonDataService.getAll().pipe(
                map(data => {
                  const commonDataItem = findCommonDataInRecords(id, data);
                  return fromActions.selectCommonDataComplete({ commonData: commonDataItem! });
                }),
                catchError(err => of(fromActions.selectCommonDataError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onGoToCommonDataListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToCommonDataListPage),
      tap(() => this.router.navigate(['common-data'])),
      map(() => fromActions.resetState({ selectedCommonData: null })),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly commonDataService: CommonDataService,
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) {}
}

export function findCommonDataInRecords(id: string, data: CommonDataObj): CommonData | null {
  const commonDataArray = Object.entries(data).reduce((prev, curr) => {
    return [...prev, ...curr[1]];
  }, [] as CommonData[]);
  if (commonDataArray.length) {
    const commonDataItem = commonDataArray.find(item => item.id === id);
    return commonDataItem ?? null;
  }
  return null;
}
