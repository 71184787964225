import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { excludeColumnFromSearch } from '@highlander/common/helpers/util';
import { AssessmentToggleOptions, IAssessment } from '@models/products-and-orders/assessments';
import { CellRendererSelectorResult, ColDef } from 'ag-grid-community';
import { CuiTableModule, formatDate } from 'cui-components';
import { startOfDay } from 'date-fns';
import { AssessmentsStore } from 'src/app/app-state/products-and-orders/assessments/assessments.store';

export const ASSESSMENTS_TABLE_ID = 'assessments-table';

@Component({
  imports: [CommonModule, CuiTableModule, RouterModule],
  selector: 'hui-assessments',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentsListComponent {
  assessmentsStore = inject(AssessmentsStore);
  ToggleOptions: AssessmentToggleOptions[] = [AssessmentToggleOptions.Open, AssessmentToggleOptions.All];
  ASSESSMENTS_TABLE_ID = ASSESSMENTS_TABLE_ID;

  columnDefs: ColDef<IAssessment>[] = [
    {
      field: 'partnerId',
      hide: true,
    },
    {
      headerName: 'Partner',
      field: 'partnerName',
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],

      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: 'Region',
      field: 'regionName',
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],

      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: 'Customer',
      field: 'customerName',
    },
    {
      headerName: 'Creation Date',
      field: 'createdAt',
      valueFormatter: ({ value }) => (value ? formatDate(new Date(value), 'yyyy-MM-dd') : ''),
      getQuickFilterText: excludeColumnFromSearch,
    },
    {
      headerName: 'Date Modified',
      field: 'updatedAt',
      cellDataType: 'date',
      valueFormatter: ({ value }) => (value ? formatDate(new Date(value), 'yyyy-MM-dd') : ''),
      filter: 'agDateColumnFilter',
      filterParams: {
        label: 'Filter by date',
        maxNumConditions: 1,
        buttons: ['reset', 'apply'],
        comparator: (filterDate: Date, cellValue: Date) => {
          const cellDate = startOfDay(cellValue);
          return cellDate.getTime() - filterDate.getTime();
        },
      },
    },
    {
      headerName: 'Created by user',
      field: 'createdByEmail',
    },
    {
      headerName: 'Requested by user',
      field: 'requestByUserEmail',
    },
    {
      field: 'status',
      cellClassRules: {
        ['xs-icon']: () => true,
      },
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],

      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressAndOrCondition: true,
      },
      cellRendererSelector: ({ data }): CellRendererSelectorResult | undefined => {
        if (!data || data.status !== 'OnHold') return undefined;

        return {
          component: 'cuiTableCellIconRenderer',
          params: {
            value: {
              iconName: 'info',
              iconTooltip: data.onHoldReason,
              cellValue: data.status,
            },
          },
        };
      },
    },
  ];

  constructor() {
    if (!this.assessmentsStore.assessments().length && !this.assessmentsStore.isLoading()) {
      this.assessmentsStore.loadAssessments();
    }
  }

  onToggleClick(toggle: AssessmentToggleOptions): void {
    this.assessmentsStore.loadAssessments(toggle);
  }

  onRowClick({ id }: IAssessment): void {
    this.assessmentsStore.goToAssessmentActions(id);
  }
}
