import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppConfig } from '@models/common';
import { AppConfigService } from './app-config.service';

export const loggingInitializer = (appConfigService: AppConfigService, loggingService: AppInsightsLoggingService): (() => Promise<any>) => {
  return () =>
    appConfigService
      .loadConfig()
      .then(() => {
        loggingService.init(appConfigService.config);
      })
      .catch(() => {});
};

@Injectable({ providedIn: 'root' })
export class AppInsightsLoggingService {
  appInsights!: ApplicationInsights;
  constructor() {}

  logPageView(name?: string, url?: string): void {
    // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, properties?: { [key: string]: any }, severityLevel?: number): void {
    if (!this.isInitialized) return;
    this.appInsights.trackException({ exception: exception, properties: properties, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackTrace({ message: message }, properties);
  }

  init(config: AppConfig): void {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: config['FRONTEND_APPINSIGHTS_INSTRUMENTATIONKEY'],
        loggingLevelTelemetry: 2,
        loggingLevelConsole: 2,
        enableRequestHeaderTracking: true,
        isStorageUseDisabled: true,
        disablePageUnloadEvents: ['unload'],
      },
    });

    this.appInsights.addTelemetryInitializer(envelope => {
      if (envelope.baseType === 'RemoteDependencyData') {
        const request = envelope!.baseData!;
        if (request['properties'] && request['properties']['requestHeaders']) {
          envelope.data = {
            CorrelationId: request['properties'].requestHeaders['x-highlander-correlation-id'],
            RenderedMessage: `FE Admin: ${envelope.baseData!['name']}`,
          };
        }
      }
    });

    this.appInsights.loadAppInsights();
  }

  private get isInitialized(): boolean {
    return !!this.appInsights;
  }
}
