import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.dashboard);

export const selectUserInactivityStats = createSelector(selectModuleState, state => state.userInactivityStats);
export const selectStats = createSelector(selectModuleState, state => state.stats);

export const selectStatsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectUserInactivityStatsLoading = createSelector(selectModuleState, state => state.isUserInactivityLoading);
