import { HttpErrorResponse } from '@angular/common/http';
import { DashboardStats, UserInactivityDashboardStats } from '@models/dashboard/dashboard.model';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Dashboard]';

export const getUserInactivityStats = createAction(`${NAMESPACE} Get User Inactivity Stats`);
export const getUserInactivityStatsComplete = createAction(
  `${NAMESPACE} Get User Inactivity Stats Complete`,
  props<{ userInactivityStats: UserInactivityDashboardStats }>(),
);
export const getUserInactivityStatsError = createAction(
  `${NAMESPACE} Get User Inactivity Stats Error`,
  props<{ err: HttpErrorResponse }>(),
);

export const getStats = createAction(`${NAMESPACE} Get Stats`);
export const getStatsComplete = createAction(`${NAMESPACE} Get Stats Complete`, props<{ stats: DashboardStats }>());
export const getStatsError = createAction(`${NAMESPACE} Get Stats Error`, props<{ err: HttpErrorResponse }>());
