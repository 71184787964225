import { patchState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { catchError, EMPTY, Observable, pipe, switchMap, tap } from 'rxjs';
export type StateDictionary<TEntity> = {
  entityList: string;
  listLoading: string;
  createLoading: string;
  selectedEntity: string;
  selectedEntityLoading: string;
  entityId: keyof TEntity;
  sortFn?: (a: TEntity, b: TEntity) => number;
};
/**
 * Creates a reusable getAll method for signal stores
 *
 * @param options Configuration for the getAll method
 * @returns An rxMethod that handles fetching all entities
 */
export const getAll = <TEntity, TParams = void>(options: {
  store: any;
  service: { getAll: (params?: TParams) => Observable<TEntity[]> };
  dictionary: StateDictionary<TEntity>;
}) => {
  const { store, service, dictionary } = options;
  const { sortFn, listLoading, entityList } = dictionary;

  return rxMethod<TParams | undefined>(
    pipe(
      tap(() => {
        patchState(store, { [listLoading]: true } as any);
      }),
      switchMap(params =>
        service.getAll(params).pipe(
          tap(entities => {
            const sortedEntities = sortFn ? [...entities].sort(sortFn) : entities;
            patchState(store, {
              [entityList]: sortedEntities,
              [listLoading]: false,
            } as any);
          }),
          catchError(() => {
            patchState(store, { [listLoading]: false } as any);
            return EMPTY;
          }),
        ),
      ),
    ),
  );
};
/**
 * Creates a reusable getOne method for signal stores
 *
 * @returns An rxMethod that handles fetching a specific entity by id
 */
export const getOne = <TEntity>(options: {
  store: any;
  service: { getOne: (id: string) => Observable<TEntity> };
  dictionary: StateDictionary<TEntity>;
}) => {
  const { store, service, dictionary } = options;
  const { selectedEntity, selectedEntityLoading } = dictionary;
  return rxMethod<string>(
    pipe(
      tap(() => {
        patchState(store, { [selectedEntityLoading]: true } as any);
      }),
      switchMap(id =>
        service.getOne(id).pipe(
          tap(entity => {
            patchState(store, {
              [selectedEntity]: entity,
              [selectedEntityLoading]: false,
            } as any);
          }),
          catchError(() => {
            patchState(store, { [selectedEntityLoading]: false } as any);
            return EMPTY;
          }),
        ),
      ),
    ),
  );
};
