import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GroupedRoles, RoleType, UpdateRoleForEntityRequest } from '@models/authorization';
import {
  ActionQueuedResponse,
  CreateIndirectProviderRequest,
  IndirectProvider,
  IndirectProviderResponse,
  Partner,
  PartnerAdminUser,
  PartnerAdminUserResponse,
  PartnerBusinessData,
  PartnerListItem,
  SetPartnerEntraTenantIdRequest,
  UpdatePartnerRequest,
} from '@models/partners';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class PartnersService {
  private readonly url = `${environment.bffUrl}/partners`;
  constructor(private httpClient: HttpClient) {}

  getPartners(includeMarkedForDeletion = false): Observable<PartnerListItem[]> {
    return this.httpClient.get<PartnerListItem[]>(this.url, { params: { includeMarkedForDeletion } });
  }

  getPartner(id: string): Observable<Partner> {
    return this.httpClient.get<Partner>(`${this.url}/${id}`);
  }

  getPartnerBusinessData(id: string): Observable<PartnerBusinessData> {
    return this.httpClient.get<PartnerBusinessData>(`${this.url}/${id}/business-data`);
  }

  setPartnerRoles(id: string, roles: UpdateRoleForEntityRequest[], filter: RoleType): Observable<GroupedRoles[]> {
    return this.httpClient.patch<GroupedRoles[]>(`${this.url}/${id}/roles`, roles, {
      params: { type: filter },
    });
  }

  getPartnerRoles(id: string, filter: RoleType): Observable<GroupedRoles[]> {
    return this.httpClient.get<GroupedRoles[]>(`${this.url}/${id}/roles`, { params: { type: filter } });
  }

  markForDeletion(id: string): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/${id}/mark-for-deletion`, undefined);
  }

  setCrmAccId(partnerId: string, crmAccountId: string): Observable<Partner> {
    return this.httpClient.post<Partner>(`${this.url}/${partnerId}/set-crm-account-id`, { crmAccountId });
  }

  getAdminUsers(partnerId: string): Observable<PartnerAdminUser[]> {
    return this.httpClient.get<PartnerAdminUserResponse[]>(`${this.url}/${partnerId}/administrators`);
  }

  setEntraTenantId(partnerId: string, req: SetPartnerEntraTenantIdRequest): Observable<Partner> {
    return this.httpClient.post<Partner>(`${this.url}/${partnerId}/set-entra-tenant-id`, req);
  }

  getAllIndirectProviders(partnerId: string): Observable<IndirectProvider[]> {
    return this.httpClient.get<IndirectProviderResponse[]>(`${this.url}/${partnerId}/indirect-providers`);
  }

  getOneIndirectProvider(partnerId: string, indirectProviderId: string): Observable<IndirectProvider> {
    return this.httpClient.get<IndirectProviderResponse>(`${this.url}/${partnerId}/indirect-providers/${indirectProviderId}`);
  }

  createIndirectProvider(partnerId: string, req: CreateIndirectProviderRequest): Observable<IndirectProvider> {
    return this.httpClient.post<IndirectProviderResponse>(`${this.url}/${partnerId}/indirect-providers`, req);
  }

  deleteIndirectProvider(partnerId: string, indirectProviderId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${partnerId}/indirect-providers/${indirectProviderId}`);
  }

  activatePartner(partnerId: string): Observable<Partner> {
    return this.httpClient.post<Partner>(`${this.url}/${partnerId}/activate`, undefined);
  }

  deactivatePartner(partnerId: string): Observable<Partner> {
    return this.httpClient.post<Partner>(`${this.url}/${partnerId}/deactivate`, undefined);
  }

  refreshCustomers(partnerId: string): Observable<ActionQueuedResponse> {
    return this.httpClient.post<ActionQueuedResponse>(`${this.url}/${partnerId}/refresh-customers`, undefined);
  }

  updatePartner(id: string, partner: UpdatePartnerRequest): Observable<Partner> {
    return this.httpClient.put<Partner>(`${this.url}/${id}`, partner);
  }
}
