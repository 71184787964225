import { NewsArticle, NewsArticleComment, NewsArticleCommunity, NewsArticleListItem, NewsArticlePromotion } from '@models/index';
import { createReducer, on } from '@ngrx/store';
import { findAndRemove, findAndReplace, sortAlphabetically } from '../helpers/helper';
import * as fromActions from './news-articles.actions';
import { ToggleOptionsName } from './news-articles.actions';
export interface State {
  toggle: ToggleOptionsName;
  newsArticles: NewsArticleListItem[];
  selectedNewsArticle: NewsArticle | null;
  isLoading: boolean;
  isSelectedNewsArticleLoading: boolean;
  isNewsArticleCreateInProgress: boolean;

  newsArticleComments: NewsArticleComment[];
  newsArticleCommentsLoading: boolean;

  selectedNewsArticleCommunityId: string | null;

  newsArticlesByCommunity: NewsArticleListItem[];
  newsArticlesByCommunityLoading: boolean;

  articlesWithoutSelectedCommunity: NewsArticleListItem[];
  articlesWithoutSelectedCommunityLoading: boolean;
}

export const initialState: State = {
  newsArticles: [],
  toggle: 'All',
  selectedNewsArticle: null,
  isLoading: false,
  isSelectedNewsArticleLoading: false,
  isNewsArticleCreateInProgress: false,

  newsArticleComments: [],
  newsArticleCommentsLoading: false,

  selectedNewsArticleCommunityId: null,

  newsArticlesByCommunity: [],
  newsArticlesByCommunityLoading: false,

  articlesWithoutSelectedCommunity: [],
  articlesWithoutSelectedCommunityLoading: false,
};

export const newsArticlesReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAllNewsArticles, (state, { toggle }) => ({ ...state, toggle, isLoading: true })),
  on(fromActions.getAllNewsArticlesComplete, (state, { newsArticles }) => ({
    ...state,
    isLoading: false,
    newsArticles: sortAlphabetically(newsArticles, sortByDateTime),
  })),
  on(fromActions.getAllNewsArticlesError, state => ({ ...state, isLoading: false })),

  on(fromActions.selectNewsArticle, state => ({ ...state, isSelectedNewsArticleLoading: true })),
  on(fromActions.selectNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    isSelectedNewsArticleLoading: false,
    selectedNewsArticle: newsArticle,
  })),
  on(fromActions.selectNewsArticleError, state => ({
    ...state,
    isSelectedNewsArticleLoading: false,
  })),

  on(fromActions.getAllNewsArticlesByCommunity, state => ({
    ...state,
    newsArticlesByCommunityLoading: true,
  })),
  on(fromActions.getAllNewsArticlesByCommunityComplete, (state, { newsArticles }) => ({
    ...state,
    newsArticlesByCommunityLoading: false,
    newsArticlesByCommunity: sortAlphabetically(newsArticles, sortByDateTime),
  })),
  on(fromActions.getAllNewsArticlesByCommunityError, state => ({
    ...state,
    newsArticlesByCommunityLoading: false,
  })),

  on(fromActions.addNewsArticle, state => ({
    ...state,
    isNewsArticleCreateInProgress: true,
    isSelectedNewsArticleLoading: true,
    newsArticlesByCommunityLoading: true,
    isLoading: true,
  })),
  on(fromActions.addNewsArticleComplete, (state, { newsArticle, communityId }) => ({
    ...state,
    selectedNewsArticle: { ...newsArticle, content: null },
    newsArticles: sortAlphabetically([...state.newsArticles, newsArticle], sortByDateTime),
    newsArticlesByCommunity: communityId ? sortAlphabetically([...state.newsArticlesByCommunity, newsArticle], sortByDateTime) : [],
    newsArticlesByCommunityLoading: false,
    isSelectedNewsArticleLoading: false,
    isNewsArticleCreateInProgress: false,
    isLoading: false,
  })),
  on(fromActions.addNewsArticleError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    newsArticlesByCommunityLoading: false,
    isNewsArticleCreateInProgress: false,
    isLoading: false,
  })),

  on(fromActions.createNewsArticleThumbnailFile, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.updateNewsArticle, state => ({
    ...state,
    isSelectedNewsArticleLoading: true,
    newsArticlesByCommunityLoading: true,
    isLoading: true,
  })),
  on(fromActions.updateNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    newsArticlesByCommunity: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticlesByCommunity, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    newsArticlesByCommunityLoading: false,
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.updateNewsArticleError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    newsArticlesByCommunityLoading: false,
    isLoading: false,
  })),

  on(fromActions.deleteSelectedNewsArticle, state => ({ ...state, isSelectedNewsArticleLoading: true })),
  on(fromActions.deleteSelectedNewsArticleComplete, state => ({
    ...state,
    isSelectedNewsArticleLoading: false,
    selectedNewsArticle: null,
    selectedVersion: null,
  })),
  on(fromActions.deleteSelectedNewsArticleError, state => ({
    ...state,
    isSelectedNewsArticleLoading: false,
  })),

  on(fromActions.activateNewsArticle, state => ({ ...state, isSelectedNewsArticleLoading: true })),
  on(fromActions.activateNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    isSelectedNewsArticleLoading: false,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === state.selectedNewsArticle!.id),
      sortByDateTime,
    ),
  })),
  on(fromActions.activateNewsArticleError, state => ({ ...state, isSelectedNewsArticleLoading: false })),

  on(fromActions.deactivateNewsArticle, state => ({ ...state, isSelectedNewsArticleLoading: true })),
  on(fromActions.deactivateNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    isSelectedNewsArticleLoading: false,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === state.selectedNewsArticle!.id),
      sortByDateTime,
    ),
  })),
  on(fromActions.deactivateNewsArticleError, state => ({ ...state, isSelectedNewsArticleLoading: false })),

  on(fromActions.setNewsArticleTopics, state => ({
    ...state,
    isSelectedNewsArticleLoading: true,
    isLoading: true,
  })),
  on(fromActions.setNewsArticleTopicsComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.setNewsArticleTopicsError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.setNewsArticleWorkloads, state => ({
    ...state,
    isSelectedNewsArticleLoading: true,
    isLoading: true,
  })),
  on(fromActions.setNewsArticleWorkloadsComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.setNewsArticleWorkloadsError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.setNewsArticleMembershipLevels, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.setNewsArticleMembershipLevelsComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.setNewsArticleMembershipLevelsError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.setNewsArticleContent, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.setNewsArticleContentComplete, (state, { newContent }) => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle!, content: newContent.content },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(
        state.newsArticles,
        state.selectedNewsArticle!,
        item => item.id === state.selectedNewsArticle!.id,
      ),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.setNewsArticleContentError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.getAllNewsArticleComments, state => ({ ...state, newsArticleCommentsLoading: true })),
  on(fromActions.getAllNewsArticleCommentsComplete, (state, { newsArticleComments }) => ({
    ...state,
    newsArticleCommentsLoading: false,
    newsArticleComments: sortAlphabetically(newsArticleComments, sortByDateTime),
  })),
  on(fromActions.getAllNewsArticleCommentsError, state => ({ ...state, newsArticleCommentsLoading: false })),

  on(fromActions.deleteNewsArticleComment, state => ({ ...state, newsArticleCommentsLoading: true })),
  on(fromActions.deleteNewsArticleCommentComplete, (state, { commentId }) => ({
    ...state,
    newsArticleComments: findAndRemoveComment(state.newsArticleComments, comment => comment.id === commentId),
    newsArticleCommentsLoading: false,
  })),
  on(fromActions.deleteNewsArticleCommentError, state => ({
    ...state,
    newsArticleCommentsLoading: false,
  })),

  on(fromActions.selectNewsArticleCommunity, (state, { communityId }) => ({
    ...state,
    selectedNewsArticleCommunityId: communityId,
  })),

  on(fromActions.addNewsArticleCommunities, (state, { isCommunityView }) => ({
    ...state,
    isSelectedNewsArticleLoading: true,
    isLoading: true,
    articlesWithoutSelectedCommunityLoading: isCommunityView,
  })),
  on(fromActions.addNewsArticleCommunitiesComplete, (state, { newsArticle, isCommunityView }) => ({
    ...state,
    selectedNewsArticle: state.selectedNewsArticle
      ? { content: state.selectedNewsArticle!.content, ...newsArticle }
      : state.selectedNewsArticle,
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    articlesWithoutSelectedCommunity: isCommunityView
      ? findAndRemove(state.articlesWithoutSelectedCommunity, article => article.id === newsArticle.id)
      : state.articlesWithoutSelectedCommunity,
    newsArticlesByCommunity: isCommunityView ? [...state.newsArticlesByCommunity, newsArticle] : state.newsArticlesByCommunity,
    isSelectedNewsArticleLoading: false,
    isLoading: false,
    articlesWithoutSelectedCommunityLoading: false,
    selectedNewsArticleCommunityId: null,
  })),
  on(fromActions.addNewsArticleCommunitiesError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    articlesWithoutSelectedCommunityLoading: false,
    isLoading: false,
  })),

  on(fromActions.updateNewsArticleCommunity, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.updateNewsArticleCommunityComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.updateNewsArticleCommunityError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.deleteNewsArticleCommunity, state => ({
    ...state,
    isSelectedNewsArticleLoading: true,
    newsArticlesByCommunityLoading: true,
    isLoading: true,
  })),
  on(fromActions.deleteNewsArticleCommunityComplete, (state, { articleId, communityId, isCommunityView }) => {
    const newsArticle = {
      ...state.selectedNewsArticle!,
      communities: findAndRemove<NewsArticleCommunity>(state.selectedNewsArticle!.communities, item => item.id === communityId),
    };
    return {
      ...state,
      selectedNewsArticle: isCommunityView ? null : newsArticle,
      newsArticles: sortAlphabetically(
        findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === articleId),
        sortByDateTime,
      ),
      newsArticlesByCommunity: findAndRemove(state.newsArticlesByCommunity, item => item.id === articleId),
      isSelectedNewsArticleLoading: false,
      newsArticlesByCommunityLoading: false,
      isLoading: false,
    };
  }),
  on(fromActions.deleteNewsArticleCommunityError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    newsArticlesByCommunityLoading: false,
    isLoading: false,
  })),

  on(fromActions.approveNewsArticleCommunity, state => ({
    ...state,
    isSelectedNewsArticleLoading: true,
    newsArticlesByCommunityLoading: true,
    isLoading: true,
  })),
  on(fromActions.approveNewsArticleCommunityComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    newsArticlesByCommunity: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticlesByCommunity, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    newsArticlesByCommunityLoading: false,
    isLoading: false,
  })),
  on(fromActions.approveNewsArticleCommunityError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    newsArticlesByCommunityLoading: false,
    isLoading: false,
  })),

  on(fromActions.addNewsArticlePromotion, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.addNewsArticlePromotionComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.addNewsArticlePromotionError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.updateNewsArticlePromotion, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.updateNewsArticlePromotionComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.updateNewsArticlePromotionError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.deleteNewsArticlePromotion, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.deleteNewsArticlePromotionComplete, (state, { articleId, communityId }) => {
    const newsArticle = {
      ...state.selectedNewsArticle!,
      promotions: findAndRemove<NewsArticlePromotion>(state.selectedNewsArticle!.promotions, item => item.communityId === communityId),
    };
    return {
      ...state,
      selectedNewsArticle: newsArticle,
      newsArticles: sortAlphabetically(
        findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === articleId),
        sortByDateTime,
      ),
      isSelectedNewsArticleLoading: false,
      isLoading: false,
    };
  }),
  on(fromActions.deleteNewsArticlePromotionError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.duplicateNewsArticle, state => ({
    ...state,
    isNewsArticleCreateInProgress: true,
    isSelectedNewsArticleLoading: true,
    newsArticlesByCommunityLoading: true,
    isLoading: true,
  })),
  on(fromActions.duplicateNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: newsArticle,
    newsArticles: sortAlphabetically([...state.newsArticles, newsArticle], sortByDateTime),
    newsArticlesByCommunity: sortAlphabetically([...state.newsArticlesByCommunity, newsArticle], sortByDateTime),
    isSelectedNewsArticleLoading: false,
    isNewsArticleCreateInProgress: false,
    isLoading: false,
    newsArticlesByCommunityLoading: false,
  })),
  on(fromActions.duplicateNewsArticleError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isNewsArticleCreateInProgress: false,
    isLoading: false,
    newsArticlesByCommunityLoading: false,
  })),
  on(fromActions.getArticlesWithoutSelectedCommunity, state => ({ ...state, articlesWithoutSelectedCommunityLoading: true })),
  on(fromActions.getArticlesWithoutSelectedCommunityComplete, (state, { articles }) => ({
    ...state,
    articlesWithoutSelectedCommunityLoading: false,
    articlesWithoutSelectedCommunity: articles,
  })),
  on(fromActions.getArticlesWithoutSelectedCommunityError, state => ({ ...state, articlesWithoutSelectedCommunityLoading: false })),
  on(fromActions.goToNewsArticleActions, state => ({ ...state, selectedNewsArticleCommunityId: null })),

  on(fromActions.getOneNewsCommunity, state => ({ ...state, isSelectedNewsCommunityLoading: true })),
  on(fromActions.getOneNewsCommunityComplete, (state, { newsCommunity }) => ({
    ...state,
    isSelectedNewsCommunityLoading: false,
    selectedNewsCommunity: newsCommunity,
  })),
  on(fromActions.getOneNewsCommunityError, state => ({ ...state, isSelectedNewsCommunityLoading: false })),
);

export const sortByDateTime = (a: NewsArticleListItem | NewsArticleComment, b: NewsArticleListItem | NewsArticleComment): number =>
  a.createdDateTime < b.createdDateTime ? 1 : -1;

export const findAndRemoveComment = (
  comments: NewsArticleComment[],
  predicate: (comment: NewsArticleComment) => boolean,
): NewsArticleComment[] => {
  return comments.reduce((acc, comment) => {
    if (!predicate(comment)) {
      const newComment = { ...comment };

      if (newComment.comments) {
        newComment.comments = findAndRemoveComment(newComment.comments, predicate);
      }

      acc.push(newComment);
    }
    return acc;
  }, [] as NewsArticleComment[]);
};
