import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.newsArticles);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectIsLoadingByCommunity = createSelector(selectModuleState, state => state.newsArticlesByCommunityLoading);
export const selectNewsArticles = createSelector(selectModuleState, state => state.newsArticles);
export const selectNewsArticlesByCommunity = createSelector(selectModuleState, state => state.newsArticlesByCommunity);
export const selectActiveNewsArticles = createSelector(selectModuleState, state =>
  state.newsArticles.filter(item => item.status === 'Active'),
);

export const selectSelectedNewsArticle = createSelector(selectModuleState, state => state.selectedNewsArticle);
export const selectToggle = createSelector(selectModuleState, state => state.toggle);
export const selectSelectedNewsArticleId = createSelector(selectModuleState, state => state.selectedNewsArticle?.id ?? null);
export const selectSelectedNewsArticleLoading = createSelector(selectModuleState, state => state.isSelectedNewsArticleLoading);

export const selectCreateNewsArticleInProgress = createSelector(selectModuleState, state => state.isNewsArticleCreateInProgress);

export const selectNewsArticleComments = createSelector(selectModuleState, state => state.newsArticleComments);
export const selectNewsArticleCommentsLoading = createSelector(selectModuleState, state => state.newsArticleCommentsLoading);

export const selectSelectedNewsArticleCommunityId = createSelector(selectModuleState, state => state.selectedNewsArticleCommunityId);
export const selectSelectedNewsArticleCommunity = createSelector(selectModuleState, state => {
  if (state.selectedNewsArticle && state.selectedNewsArticle.communities?.length) {
    return state.selectedNewsArticle.communities.find(item => item.id === state.selectedNewsArticleCommunityId) ?? null;
  } else {
    return null;
  }
});

export const selectArticlesWithoutSelectedCommunity = createSelector(selectModuleState, state => state.articlesWithoutSelectedCommunity);
export const selectArticlesWithoutSelectedCommunityLoading = createSelector(
  selectModuleState,
  state => state.articlesWithoutSelectedCommunityLoading,
);
