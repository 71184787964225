import { DashboardStats, UserInactivityDashboardStats } from '@models/dashboard/dashboard.model';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './dashboard.actions';

export interface State {
  userInactivityStats: UserInactivityDashboardStats | null;
  stats: DashboardStats | null;
  isLoading: boolean;
  isUserInactivityLoading: boolean;
}

export const initialState: State = {
  userInactivityStats: null,
  stats: null,
  isLoading: false,
  isUserInactivityLoading: false,
};

export const dashboardReducer = createReducer(
  initialState,

  on(fromActions.getUserInactivityStats, state => ({ ...state, isUserInactivityLoading: true })),
  on(fromActions.getUserInactivityStatsComplete, (state, { userInactivityStats }) => ({
    ...state,
    userInactivityStats,
    isUserInactivityLoading: false,
  })),
  on(fromActions.getUserInactivityStatsError, state => ({ ...state, isUserInactivityLoading: false })),

  on(fromActions.getStats, state => ({ ...state, isLoading: true })),
  on(fromActions.getStatsComplete, (state, { stats }) => ({ ...state, stats, isLoading: false })),
  on(fromActions.getStatsError, state => ({ ...state, isLoading: false })),
);
