import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.communities);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectIsCommunityCreateLoading = createSelector(selectModuleState, state => state.communityCreateLoading);
export const selectCommunities = createSelector(selectModuleState, state => state.communities);

export const selectSelectedCommunity = createSelector(selectModuleState, state => state.selectedCommunity);
export const selectSelectedCommunityId = createSelector(selectModuleState, state => state.selectedCommunity && state.selectedCommunity.id);
export const selectSelectedCommunityLoading = createSelector(selectModuleState, state => state.selectedCommunityLoading);

export const selectCommunitiesBase = createSelector(selectModuleState, state => state.communitiesBase);
export const selectCommunitiesBaseLoading = createSelector(selectModuleState, state => state.communitiesBaseLoading);

export const selectNewsCommunities = createSelector(selectModuleState, state => state.newsCommunities);
export const selectNewsCommunitiesLoading = createSelector(selectModuleState, state => state.newsCommunitiesLoading);
