import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as fromActions from './dashboard.actions';
import { DashboardService } from './dashboard.service';

@Injectable()
export class DashboardEffects {
  onGetUserStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUserInactivityStats),
      switchMap(() =>
        this.dashboardService.getUserStats().pipe(
          map(userInactivityStats => fromActions.getUserInactivityStatsComplete({ userInactivityStats })),
          catchError(err => of(fromActions.getUserInactivityStatsError({ err }))),
        ),
      ),
    ),
  );

  onGetStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getStats),
      switchMap(() =>
        this.dashboardService.getStats().pipe(
          map(stats => fromActions.getStatsComplete({ stats })),
          catchError(err => of(fromActions.getStatsError({ err }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
  ) {}
}
