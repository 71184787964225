import * as appStore from './app.store';

import * as usersActions from './users/users.actions';
import { UsersEffects } from './users/users.effects';
import * as usersSelectors from './users/users.selector';
import { UsersService } from './users/users.service';

import * as partnersActions from './partners/partners.actions';
import { PartnersEffects } from './partners/partners.effects';
import * as partnersSelectors from './partners/partners.selector';
import { PartnersService } from './partners/partners.service';

import * as rolesActions from './authorization/roles/roles.actions';
import { RolesEffects } from './authorization/roles/roles.effects';
import * as rolesSelectors from './authorization/roles/roles.selector';
import { RolesService } from './authorization/roles/roles.service';

import * as functionalRolesActions from './authorization/functional-roles/functional-roles.actions';
import { FunctionalRolesEffects } from './authorization/functional-roles/functional-roles.effects';
import * as functionalRolesSelectors from './authorization/functional-roles/functional-roles.selector';
import { FunctionalRolesService } from './authorization/functional-roles/functional-roles.service';

import * as roleGroupsActions from './authorization/role-groups/role-groups.actions';
import { RoleGroupsEffects } from './authorization/role-groups/role-groups.effects';
import * as roleGroupsSelectors from './authorization/role-groups/role-groups.selector';
import { RoleGroupsService } from './authorization/role-groups/role-groups.service';

import * as permissionsActions from './authorization/permissions/permissions.actions';
import { PermissionsEffects } from './authorization/permissions/permissions.effects';
import * as permissionsSelectors from './authorization/permissions/permissions.selector';
import { PermissionService } from './authorization/permissions/permissions.service';

import * as documentsActions from './documents/documents.actions';
import { DocumentsEffects } from './documents/documents.effects';
import * as documentsSelectors from './documents/documents.selector';
import { DocumentsService } from './documents/documents.service';

import * as commonDataActions from './common-data/common-data.actions';
import { CommonDataEffects } from './common-data/common-data.effects';
import * as commonDataSelectors from './common-data/common-data.selector';
import { CommonDataService } from './common-data/common-data.service';

import * as identityActions from './identity/identity.actions';
import { IdentityEffects } from './identity/identity.effects';
import * as identitySelectors from './identity/identity.selectors';
import { IdentityService } from './identity/identity.service';

import * as actionLogsActions from './action-logs/action-logs.actions';
import { ActionLogsEffects } from './action-logs/action-logs.effects';
import * as actionLogsSelectors from './action-logs/action-logs.selector';
import { ActionLogsService } from './action-logs/action-logs.service';

import * as agreementsActions from './agreements/agreements.actions';
import { AgreementsEffects } from './agreements/agreements.effects';
import * as agreementSelectors from './agreements/agreements.selector';
import { AgreementsService } from './agreements/agreements.service';

import * as customersActions from './customers/customers.actions';
import { CustomersEffects } from './customers/customers.effects';
import * as customerSelectors from './customers/customers.selector';
import { CustomersService } from './customers/customers.service';

import * as announcementsActions from './announcements/announcements.actions';
import { AnnouncementsEffects } from './announcements/announcements.effects';
import * as announcementSelectors from './announcements/announcements.selector';
import { AnnouncementsService } from './announcements/announcements.service';

import * as topicsActions from './marketing/topics/topics.actions';
import { TopicsEffects } from './marketing/topics/topics.effects';
import * as topicsSelectors from './marketing/topics/topics.selector';
import { TopicsService } from './marketing/topics/topics.service';

import * as branchesActions from './file-management/branches/branches.actions';
import { BranchesEffects } from './file-management/branches/branches.effects';
import * as branchesSelectors from './file-management/branches/branches.selector';
import { BranchesService } from './file-management/branches/branches.service';

import * as filesActions from './file-management/files/files.actions';
import { FilesEffects } from './file-management/files/files.effects';
import * as filesSelectors from './file-management/files/files.selector';
import { FilesService } from './file-management/files/files.service';

import * as productsActions from './products-and-orders/products/products.actions';
import { ProductsEffects } from './products-and-orders/products/products.effects';
import * as productsSelectors from './products-and-orders/products/products.selector';
import { ProductsService } from './products-and-orders/products/products.service';

import * as receiverGroupsActions from './notifications/receiver-groups/receiver-groups.actions';
import { ReceiverGroupsEffects } from './notifications/receiver-groups/receiver-groups.effects';
import * as receiverGroupsSelectors from './notifications/receiver-groups/receiver-groups.selector';
import { ReceiverGroupsService } from './notifications/receiver-groups/receiver-groups.service';

import * as externalTemplatesActions from './notifications/external-templates/external-templates.actions';
import { ExternalTemplatesEffects } from './notifications/external-templates/external-templates.effects';
import * as externalTemplatesSelectors from './notifications/external-templates/external-templates.selector';
import { ExternalTemplatesService } from './notifications/external-templates/external-templates.service';

import * as templateGroupsActions from './notifications/template-groups/template-groups.actions';
import { TemplateGroupsEffects } from './notifications/template-groups/template-groups.effects';
import * as templateGroupsSelectors from './notifications/template-groups/template-groups.selector';
import { TemplateGroupsService } from './notifications/template-groups/template-groups.service';

import * as templatesActions from './notifications/templates/templates.actions';
import { TemplatesEffects } from './notifications/templates/templates.effects';
import * as templatesSelectors from './notifications/templates/templates.selector';
import { TemplatesService } from './notifications/templates/templates.service';

import * as logsActions from './notifications/logs/logs.actions';
import { NotificationLogsEffects } from './notifications/logs/logs.effects';
import * as logsSelectors from './notifications/logs/logs.selector';
import { NotificationLogsService } from './notifications/logs/logs.service';

import * as newsArticlesActions from './news-articles/news-articles.actions';
import { NewsArticlesEffects } from './news-articles/news-articles.effects';
import * as newsArticlesSelectors from './news-articles/news-articles.selector';
import { NewsArticlesService } from './news-articles/news-articles.service';

import { AssessmentsService } from './products-and-orders/assessments/assessments.service';

import * as commentsActions from './marketing/comments/comments.actions';
import { CommentsEffects } from './marketing/comments/comments.effects';
import * as commentsSelectors from './marketing/comments/comments.selector';

import * as customerReportsActions from './products-and-orders/customer-reports/customer-reports.actions';
import { CustomerReportsEffects } from './products-and-orders/customer-reports/customer-reports.effects';
import * as customerReportsSelectors from './products-and-orders/customer-reports/customer-reports.selector';
import { CustomerReportsService } from './products-and-orders/customer-reports/customer-reports.service';

import * as cspTenantsActions from './csp/tenants/tenants.actions';
import { CspTenantsEffects } from './csp/tenants/tenants.effects';
import * as cspTenantsSelectors from './csp/tenants/tenants.selector';
import { CspTenantsService } from './csp/tenants/tenants.service';

import * as actionTypesActions from './actions/action-types/action-types.actions';
import { ActionTypesEffects } from './actions/action-types/action-types.effects';
import * as actionTypesSelectors from './actions/action-types/action-types.selector';
import { ActionTypesService } from './actions/action-types/action-types.service';

import * as cspUtilActions from './csp/csp-util/csp-util.actions';
import { CspUtilEffects } from './csp/csp-util/csp-util.effects';
import * as cspUtilSelectors from './csp/csp-util/csp-util.selector';
import { CspUtilService } from './csp/csp-util/csp-util.service';

import * as communitiesActions from './communities/communities.actions';
import { CommunitiesEffects } from './communities/communities.effects';
import * as communitiesSelectors from './communities/communities.selector';
import { CommunitiesService } from './communities/communities.service';

import * as requestsActions from './products-and-orders/requests/requests.actions';
import { RequestsEffects } from './products-and-orders/requests/requests.effects';
import * as requestsSelectors from './products-and-orders/requests/requests.selector';
import { RequestsService } from './products-and-orders/requests/requests.service';

import * as dashboardActions from './dashboard/dashboard.actions';
import { DashboardEffects } from './dashboard/dashboard.effects';
import * as dashboardSelectors from './dashboard/dashboard.selectors';
import { DashboardService } from './dashboard/dashboard.service';

// Signal states
export * from './products-and-orders/assessments/assessments.store';

export {
  actionLogsActions,
  actionLogsSelectors,
  actionTypesActions,
  actionTypesSelectors,
  agreementsActions,
  agreementSelectors,
  announcementsActions,
  announcementSelectors,
  appStore,
  branchesActions,
  branchesSelectors,
  commentsActions,
  commentsSelectors,
  commonDataActions,
  commonDataSelectors,
  communitiesActions,
  communitiesSelectors,
  cspTenantsActions,
  cspTenantsSelectors,
  cspUtilActions,
  cspUtilSelectors,
  customerReportsActions,
  customerReportsSelectors,
  customersActions,
  customerSelectors,
  dashboardActions,
  dashboardSelectors,
  documentsActions,
  documentsSelectors,
  externalTemplatesActions,
  externalTemplatesSelectors,
  filesActions,
  filesSelectors,
  functionalRolesActions,
  functionalRolesSelectors,
  identityActions,
  identitySelectors,
  logsActions,
  logsSelectors,
  newsArticlesActions,
  newsArticlesSelectors,
  partnersActions,
  partnersSelectors,
  permissionsActions,
  permissionsSelectors,
  productsActions,
  productsSelectors,
  receiverGroupsActions,
  receiverGroupsSelectors,
  requestsActions,
  requestsSelectors,
  roleGroupsActions,
  roleGroupsSelectors,
  rolesActions,
  rolesSelectors,
  templateGroupsActions,
  templateGroupsSelectors,
  templatesActions,
  templatesSelectors,
  topicsActions,
  topicsSelectors,
  usersActions,
  usersSelectors,
};

export const ROOT_STATE_SERVICES = [
  IdentityService,
  UsersService,
  ActionLogsService,
  DocumentsService,
  CommonDataService,
  PartnersService,
  PermissionService,
  RolesService,
  RoleGroupsService,
  AgreementsService,
  CustomersService,
  AnnouncementsService,
  TopicsService,
  BranchesService,
  FilesService,
  ProductsService,
  ReceiverGroupsService,
  ExternalTemplatesService,
  TemplateGroupsService,
  FunctionalRolesService,
  TemplatesService,
  NotificationLogsService,
  NotificationLogsService,
  NewsArticlesService,
  AssessmentsService,
  CspTenantsService,
  ActionTypesService,
  CspUtilService,
  CustomerReportsService,
  CommunitiesService,
  RequestsService,
  DashboardService,
];

export const ROOT_STATE_EFFECTS = [
  IdentityEffects,
  UsersEffects,
  ActionLogsEffects,
  DocumentsEffects,
  CommonDataEffects,
  PartnersEffects,
  PermissionsEffects,
  RolesEffects,
  RoleGroupsEffects,
  AgreementsEffects,
  CustomersEffects,
  AnnouncementsEffects,
  TopicsEffects,
  BranchesEffects,
  FilesEffects,
  ProductsEffects,
  ReceiverGroupsEffects,
  ExternalTemplatesEffects,
  TemplateGroupsEffects,
  FunctionalRolesEffects,
  TemplatesEffects,
  NewsArticlesEffects,
  NotificationLogsEffects,
  CommentsEffects,
  CspTenantsEffects,
  CustomerReportsEffects,
  ActionTypesEffects,
  CspUtilEffects,
  CommunitiesEffects,
  RequestsEffects,
  DashboardEffects,
];
