import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { communitiesSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { isNotNullOrUndefined } from 'cui-components';
import { catchError, filter, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../app.store';
import * as fromActions from './communities.actions';
import { CommunitiesService } from './communities.service';

@Injectable()
export class CommunitiesEffects {
  onGetCommunities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCommunities),
      switchMap(() =>
        this.communitiesService.getCommunities().pipe(
          map(communities => fromActions.getCommunitiesComplete({ communities })),
          catchError(err => of(fromActions.getCommunitiesError({ err }))),
        ),
      ),
    ),
  );

  onGetCommunitiesBase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCommunitiesBase),
      switchMap(() =>
        this.communitiesService.getCommunitiesBase().pipe(
          map(communities => fromActions.getCommunitiesBaseComplete({ communities })),
          catchError(err => of(fromActions.getCommunitiesBaseError({ err }))),
        ),
      ),
    ),
  );

  onGetNewsCommunities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getNewsCommunities),
      switchMap(() =>
        this.communitiesService.getNewsCommunities().pipe(
          map(communities => fromActions.getNewsCommunitiesComplete({ communities })),
          catchError(err => of(fromActions.getNewsCommunitiesError({ err }))),
        ),
      ),
    ),
  );

  onAddCommunity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addCommunity),
      switchMap(({ community }) =>
        this.communitiesService.add(community).pipe(
          map(community => fromActions.addCommunityComplete({ community })),
          catchError(err => of(fromActions.addCommunityError({ err }))),
        ),
      ),
    ),
  );

  onAddCommunityComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addCommunityComplete),
        tap(({ community }) => this.router.navigate(['marketing', 'communities', community.id])),
      ),
    { dispatch: false },
  );

  onUpdateCommunity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateCommunity),
      switchMap(({ id, community }) =>
        this.communitiesService.update(id, community).pipe(
          map(community => fromActions.updateCommunityComplete({ community })),
          catchError(err => of(fromActions.updateCommunityError({ err }))),
        ),
      ),
    ),
  );

  onSelectCommunity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectCommunity),
      switchMap(({ id }) =>
        this.communitiesService.get(id).pipe(
          map(community => fromActions.selectCommunityComplete({ community })),
          catchError(err => of(fromActions.selectCommunityError({ err }))),
        ),
      ),
    ),
  );

  onDeleteCommunity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedCommunity),
      switchMap(() =>
        this.store.pipe(
          select(communitiesSelectors.selectSelectedCommunity),
          filter(isNotNullOrUndefined),
          take(1),
          switchMap(community =>
            this.communitiesService.delete(community.id).pipe(
              map(() => fromActions.deleteSelectedCommunityComplete()),
              catchError(err => of(fromActions.deleteSelectedCommunityError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteCommunityComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedCommunityComplete),
      tap(() => this.router.navigate(['marketing', 'communities'])),
      map(() => fromActions.getCommunities()),
    ),
  );

  onToggleNewsCommunityAutomaticApproval = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.toggleNewsCommunityAutomaticApproval),
      switchMap(({ id, req }) =>
        this.communitiesService.toggleAutomaticApproval(id, req).pipe(
          map(community => fromActions.toggleNewsCommunityAutomaticApprovalComplete({ community })),
          catchError(err => of(fromActions.toggleNewsCommunityAutomaticApprovalError({ err }))),
        ),
      ),
    ),
  );

  onGoToCommunityActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToCommunityActions),
        tap(({ id }) => this.router.navigate(['marketing', 'communities', id])),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToListPage),
      tap(() => this.router.navigate(['marketing', 'communities'])),
      map(() => fromActions.resetState({ selectedCommunity: null })),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private communitiesService: CommunitiesService,
  ) {}
}
