import { inject } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { patchState } from '@ngrx/signals';
import { filter, Subscription } from 'rxjs';
/**
 * Creates a route-based state reset handler that can be used in any signal store
 * @param routePath The path segment to monitor (when navigating away from this path, state is reset)
 * @returns A function that can be called inside withMethods to set up state reset
 */
export function createStateResetHandler<T>(routePath: string) {
  return (store: { [key: string]: any }, initialState: T): Subscription => {
    const router = inject(Router);
    // Set up subscription to navigation events
    return router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        filter((event: NavigationStart) => !event.url.includes(routePath)),
      )
      .subscribe(() => {
        try {
          patchState(store as any, initialState as any);
        } catch (error) {
          console.error('Error while resetting state:', error);
        }
      });
  };
}

/**
 * Creates a route-based state reset handler that can be used in any signal store
 * @param routePath The path segment to monitor (when navigating to this specific path, state is reset)
 * @param state The partial state object to apply to store state
 * @returns A function that can be called inside withMethods to set up state reset
 */

export function createCustomStateSetOnUrlHandler<T>(routePath: string, state: Partial<T>) {
  return (store: { [key: string]: any }): Subscription => {
    const router = inject(Router);
    // Set up subscription to navigation events
    return router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        filter((event: NavigationStart) => event.url === routePath),
      )
      .subscribe(() => {
        try {
          patchState(store as any, state as any);
        } catch (error) {
          console.error('Error while resetting state:', error);
        }
      });
  };
}
