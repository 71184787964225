import { HttpErrorResponse } from '@angular/common/http';
import {
  Community,
  CommunityBase,
  CommunityListItem,
  CreateCommunityRequest,
  NewsCommunity,
  UpdateCommunityRequest,
} from '@models/communities';
import { NewsCommunityApproveAutomaticallyRequest } from '@models/news-article';
import { createAction, props } from '@ngrx/store';
import { State } from './communities.reducer';

const NAMESPACE = '[Communities]';

export const getCommunities = createAction(`${NAMESPACE} GetCommunities`);
export const getCommunitiesComplete = createAction(`${NAMESPACE} GetCommunitiesComplete`, props<{ communities: CommunityListItem[] }>());
export const getCommunitiesError = createAction(`${NAMESPACE} GetCommunitiesError`, props<{ err: HttpErrorResponse }>());

export const getCommunitiesBase = createAction(`${NAMESPACE} GetCommunitiesBase`);
export const getCommunitiesBaseComplete = createAction(
  `${NAMESPACE} GetCommunitiesBaseComplete`,
  props<{ communities: CommunityBase[] }>(),
);
export const getCommunitiesBaseError = createAction(`${NAMESPACE} GetCommunitiesBaseError`, props<{ err: HttpErrorResponse }>());

export const getNewsCommunities = createAction(`${NAMESPACE} GetNewsCommunities`);
export const getNewsCommunitiesComplete = createAction(
  `${NAMESPACE} GetNewsCommunitiesComplete`,
  props<{ communities: NewsCommunity[] }>(),
);
export const getNewsCommunitiesError = createAction(`${NAMESPACE} GetNewsCommunitiesError`, props<{ err: HttpErrorResponse }>());

export const addCommunity = createAction(`${NAMESPACE} AddCommunity`, props<{ community: CreateCommunityRequest }>());
export const addCommunityComplete = createAction(`${NAMESPACE} AddCommunityComplete`, props<{ community: Community }>());
export const addCommunityError = createAction(`${NAMESPACE} AddCommunityError`, props<{ err: HttpErrorResponse }>());

export const updateCommunity = createAction(`${NAMESPACE} UpdateCommunity`, props<{ id: string; community: UpdateCommunityRequest }>());
export const updateCommunityComplete = createAction(`${NAMESPACE} UpdateCommunityComplete`, props<{ community: Community }>());
export const updateCommunityError = createAction(`${NAMESPACE} UpdateCommunityError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedCommunity = createAction(`${NAMESPACE} DeleteSelectedCommunity`);
export const deleteSelectedCommunityComplete = createAction(`${NAMESPACE} DeleteSelectedCommunityComplete`);
export const deleteSelectedCommunityError = createAction(`${NAMESPACE} DeleteSelectedCommunityError`, props<{ err: HttpErrorResponse }>());

export const selectCommunity = createAction(`${NAMESPACE} SelectCommunity`, props<{ id: string }>());
export const selectCommunityComplete = createAction(`${NAMESPACE} SelectCommunityComplete`, props<{ community: Community }>());
export const selectCommunityError = createAction(`${NAMESPACE} SelectCommunityError`, props<{ err: HttpErrorResponse }>());

export const toggleNewsCommunityAutomaticApproval = createAction(
  `${NAMESPACE} ToggleNewsCommunityAutomaticApproval`,
  props<{ id: string; req: NewsCommunityApproveAutomaticallyRequest }>(),
);
export const toggleNewsCommunityAutomaticApprovalComplete = createAction(
  `${NAMESPACE} ToggleNewsCommunityAutomaticApprovalComplete`,
  props<{ community: Community }>(),
);
export const toggleNewsCommunityAutomaticApprovalError = createAction(
  `${NAMESPACE} ToggleNewsCommunityAutomaticApprovalError`,
  props<{ err: HttpErrorResponse }>(),
);

export const goToCommunityActions = createAction(`${NAMESPACE} GoToCommunityActions`, props<{ id: string }>());
export const goToListPage = createAction(`${NAMESPACE} GoToListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
