import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Community,
  CommunityBase,
  CommunityListItem,
  CreateCommunityRequest,
  NewsCommunity,
  UpdateCommunityRequest,
} from '@models/communities';
import { NewsCommunityApproveAutomaticallyRequest } from '@models/news-article';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CommunitiesService {
  private readonly url = `${environment.bffUrl}/communities`;

  constructor(private httpClient: HttpClient) {}

  getCommunities(): Observable<CommunityListItem[]> {
    return this.httpClient.get<CommunityListItem[]>(this.url);
  }

  getCommunitiesBase(): Observable<CommunityBase[]> {
    return this.httpClient.get<CommunityBase[]>(`${this.url}/base`);
  }

  getNewsCommunities(): Observable<NewsCommunity[]> {
    return this.httpClient.get<NewsCommunity[]>(`${this.url}/news-communities`);
  }

  get(id: string): Observable<Community> {
    return this.httpClient.get<Community>(`${this.url}/${id}`);
  }

  add(community: CreateCommunityRequest): Observable<Community> {
    return this.httpClient.post<Community>(this.url, community);
  }

  update(id: string, community: UpdateCommunityRequest): Observable<Community> {
    return this.httpClient.put<Community>(`${this.url}/${id}`, community);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }

  toggleAutomaticApproval(id: string, req: NewsCommunityApproveAutomaticallyRequest): Observable<Community> {
    return this.httpClient.post<Community>(`${this.url}/${id}/toggle-automatic-approval`, req);
  }
}
