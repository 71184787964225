import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardStats, UserInactivityDashboardStats } from '@models/dashboard/dashboard.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class DashboardService {
  private readonly url = `${environment.bffUrl}/dashboard`;

  constructor(private httpClient: HttpClient) {}

  getUserStats(): Observable<UserInactivityDashboardStats> {
    return this.httpClient.get<UserInactivityDashboardStats>(`${this.url}/users/stats/inactivity`);
  }

  getStats(): Observable<DashboardStats> {
    return this.httpClient.get<DashboardStats>(`${this.url}/stats`);
  }
}
