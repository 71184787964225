import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapDefinedParams } from '@highlander/services/helpers';
import {
  AddNewsArticlePromotionRequest,
  CommentData,
  CommentsQueryParams,
  CreateNewsArticleRequest,
  NewsArticle,
  NewsArticleComment,
  NewsArticleCreateFileResponse,
  NewsArticleListItem,
  NewsArticleListItemResponse,
  NewsArticleResponse,
  NewsArticleStatus,
  NewsArticleUpdateContentRequest,
  NewsArticleUpdateContentResponse,
  UpdateNewsArticleCommunityRequest,
  UpdateNewsArticlePromotionRequest,
  UpdateNewsArticleRequest,
} from '@models/news-article/news-article.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

type GetNewsArticlesParams = Partial<{
  isCommunityApproved: boolean;
  hasCommunities: boolean;
  communityId: string;
  topicId: string;
  workloadId: string;
  status: NewsArticleStatus;
  publishedFrom: string;
  publishedTo: string;
  search: string;
  onlyPromoted: string;
  includeUserReactions: boolean;
  $orderBy: 'PublishedDate' | 'LikesCount';
}>;

@Injectable()
export class NewsArticlesService {
  private readonly url = `${environment.bffUrl}/news-articles`;
  constructor(private http: HttpClient) {}

  getAll(params?: GetNewsArticlesParams): Observable<NewsArticleListItem[]> {
    return this.http.get<NewsArticleListItemResponse[]>(this.url, { params });
  }

  getOne(id: string, includeContent: boolean): Observable<NewsArticle> {
    return this.http.get<NewsArticleResponse>(`${this.url}/${id}`, { params: { includeContent: includeContent } });
  }

  create(req: CreateNewsArticleRequest): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(this.url, req);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}`);
  }

  update(id: string, newsArticle: UpdateNewsArticleRequest): Observable<NewsArticleListItem> {
    return this.http.put<NewsArticleListItemResponse>(`${this.url}/${id}`, newsArticle);
  }

  activate(id: string): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${id}/activate`, null);
  }

  deactivate(id: string): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${id}/deactivate`, null);
  }

  setContent(id: string, req: NewsArticleUpdateContentRequest): Observable<NewsArticleUpdateContentResponse> {
    return this.http.post<NewsArticleUpdateContentResponse>(`${this.url}/${id}/content`, req);
  }

  setWorkloads(id: string, req: string[]): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${id}/set-workloads`, req);
  }

  setTopics(id: string, req: string[]): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${id}/set-topics`, req);
  }

  setMembershipLevels(id: string, req: string[]): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${id}/set-membership-levels`, req);
  }

  createThumbnailFile(id: string): Observable<NewsArticleCreateFileResponse> {
    return this.http.post<NewsArticleCreateFileResponse>(`${this.url}/${id}/files`, null);
  }

  getFile(articleId: string, fileId: string): Observable<any> {
    return this.http.get<string>(`${this.url}/${articleId}/files/${fileId}`);
  }

  getAllComments(params: CommentsQueryParams): Observable<CommentData[]> {
    return this.http.get<CommentData[]>(`${this.url}-comments`, { params: mapDefinedParams(params) });
  }

  getAllNewsArticleComments(articleId: string, includeSubComments: boolean, communityId?: string): Observable<NewsArticleComment[]> {
    return this.http.get<NewsArticleComment[]>(`${this.url}/${articleId}/comments`, {
      params: mapDefinedParams({ includeSubComments, communityId }),
    });
  }

  getOneComment(commentId: string, articleId: string): Observable<NewsArticleComment> {
    return this.http.get<NewsArticleComment>(`${this.url}/${articleId}/comments/${commentId}`);
  }

  deleteComment(articleId: string, commentId: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${articleId}/comments/${commentId}`);
  }

  addCommunities(articleId: string, req: string[]): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${articleId}/set-communities`, req);
  }

  updateCommunity(articleId: string, communityId: string, req: UpdateNewsArticleCommunityRequest): Observable<NewsArticleListItem> {
    return this.http.put<NewsArticleListItemResponse>(`${this.url}/${articleId}/communities/${communityId}`, req);
  }

  deleteCommunity(articleId: string, communityId: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${articleId}/communities/${communityId}`);
  }

  approveCommunity(articleId: string, communityId: string): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${articleId}/communities/${communityId}/approve`, null);
  }

  addPromotion(articleId: string, req: AddNewsArticlePromotionRequest): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${articleId}/promotion`, req);
  }

  updatePromotion(articleId: string, communityId: string, req: UpdateNewsArticlePromotionRequest): Observable<NewsArticleListItem> {
    return this.http.put<NewsArticleListItemResponse>(`${this.url}/${articleId}/promotion/${communityId}`, req);
  }

  deletePromotion(articleId: string, communityId: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${articleId}/promotion/${communityId}`);
  }

  duplicate(articleId: string, params: { communityId?: string } = {}): Observable<NewsArticle> {
    return this.http.post<NewsArticleResponse>(`${this.url}/${articleId}/copy`, undefined, { params });
  }
}
