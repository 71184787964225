<cui-table
  id="assessments-list-table"
  [tableId]="ASSESSMENTS_TABLE_ID"
  [domLayout]="false"
  [selectedRowId]="assessmentsStore.selectedAssessmentId()"
  [isLoading]="assessmentsStore.isLoading()"
  [rowData]="assessmentsStore.assessments()"
  [toggleDefaultOption]="assessmentsStore.toggleOption()"
  [toggleOptions]="ToggleOptions"
  [columnDefs]="columnDefs"
  (toggleClicked)="onToggleClick($event)"
  (rowClicked)="onRowClick($event)"
></cui-table>

<router-outlet></router-outlet>
