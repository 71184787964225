import { Community, CommunityBase, CommunityListItem, NewsCommunity } from '@models/communities';
import { createReducer, on } from '@ngrx/store';

import { findAndReplace, predicateForSorting, sortAlphabetically } from '../helpers/helper';
import * as fromActions from './communities.actions';

export interface State {
  isLoading: boolean;
  communityCreateLoading: boolean;
  communities: CommunityListItem[];

  selectedCommunity: Community | null;
  selectedCommunityLoading: boolean;

  communitiesBase: CommunityBase[];
  communitiesBaseLoading: boolean;

  newsCommunities: NewsCommunity[];
  newsCommunitiesLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  communityCreateLoading: false,
  communities: [],

  selectedCommunity: null,
  selectedCommunityLoading: false,

  communitiesBase: [],
  communitiesBaseLoading: false,

  newsCommunities: [],
  newsCommunitiesLoading: false,
};

export const communitiesReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getCommunities, state => ({ ...state, isLoading: true })),
  on(fromActions.getCommunitiesComplete, (state, { communities }) => ({
    ...state,
    communities: sortAlphabetically(communities, predicateForSorting('name')),
    isLoading: false,
  })),
  on(fromActions.getCommunitiesError, state => ({ ...state, isLoading: false })),

  on(fromActions.addCommunity, state => ({ ...state, communityCreateLoading: true, isLoading: true })),
  on(fromActions.addCommunityComplete, (state, { community }) => ({
    ...state,
    isLoading: false,
    communityCreateLoading: false,
    communities: sortAlphabetically([...state.communities, community], predicateForSorting('name')),
  })),
  on(fromActions.addCommunityError, state => ({ ...state, isLoading: false, communityCreateLoading: false })),

  on(fromActions.updateCommunity, state => ({ ...state, selectedCommunityLoading: true, isLoading: true })),
  on(fromActions.updateCommunityComplete, (state, { community }) => ({
    ...state,
    isLoading: false,
    selectedCommunityLoading: false,
    selectedCommunity: community,
    communities: sortAlphabetically(
      findAndReplace<CommunityListItem>(state.communities, community, item => item.id === community.id),
      predicateForSorting('name'),
    ),
  })),
  on(fromActions.updateCommunityError, state => ({
    ...state,
    selectedCommunity: { ...state.selectedCommunity! },
    isLoading: false,
    selectedCommunityLoading: false,
  })),

  on(fromActions.selectCommunity, state => ({ ...state, selectedCommunityLoading: true })),
  on(fromActions.selectCommunityComplete, (state, { community }) => ({
    ...state,
    selectedCommunityLoading: false,
    selectedCommunity: community,
  })),
  on(fromActions.selectCommunityError, state => ({ ...state, selectedCommunityLoading: false })),

  on(fromActions.deleteSelectedCommunity, state => ({ ...state, selectedCommunityLoading: true })),
  on(fromActions.deleteSelectedCommunityComplete, state => ({
    ...state,
    selectedCommunityLoading: false,
    selectedCommunity: null,
  })),
  on(fromActions.deleteSelectedCommunityError, state => ({
    ...state,
    selectedCommunityLoading: false,
  })),

  on(fromActions.toggleNewsCommunityAutomaticApproval, state => ({ ...state, selectedCommunityLoading: true })),
  on(fromActions.toggleNewsCommunityAutomaticApprovalComplete, (state, { community }) => ({
    ...state,
    selectedCommunityLoading: false,
    selectedCommunity: community,
    communities: findAndReplace<CommunityListItem>(state.communities, community, item => item.id === community.id),
  })),
  on(fromActions.toggleNewsCommunityAutomaticApprovalError, state => ({ ...state, selectedCommunityLoading: false })),

  on(fromActions.getCommunitiesBase, state => ({ ...state, communitiesBaseLoading: true })),
  on(fromActions.getCommunitiesBaseComplete, (state, { communities }) => ({
    ...state,
    communitiesBase: communities,
    communitiesBaseLoading: false,
  })),
  on(fromActions.getCommunitiesBaseError, state => ({ ...state, communitiesBaseLoading: false })),

  on(fromActions.getNewsCommunities, state => ({ ...state, newsCommunitiesLoading: true })),
  on(fromActions.getNewsCommunitiesComplete, (state, { communities }) => ({
    ...state,
    newsCommunities: communities,
    newsCommunitiesLoading: false,
  })),
  on(fromActions.getNewsCommunitiesError, state => ({ ...state, newsCommunitiesLoading: false })),
);
